var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-lg-10"},[_c('h2',{staticClass:"mb-0 text-center"},[_vm._v(_vm._s(_vm.notice.title))]),_c('div',{staticClass:"notice-comment"},[_c('div',[_c('i',{staticClass:"fa fa-comments"}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.notice.reviews))])]),_c('div',[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.notice.views))]),_c('i',{staticClass:"fa fa-eye"})])]),_c('img',{staticClass:"img-fluid img-thumbnail mb-3",attrs:{"src":_vm.notice.image,"alt":""}}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.notice.content)}}),_c('br'),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8"},[_c('hr'),_c('h6',[_vm._v(_vm._s(_vm.$t('comments')))]),_c('ul',{staticClass:"list-unstyled comments-list"},_vm._l((_vm.notice.my_reviews),function(comment){return _c('li',{key:comment.id},[_c('img',{attrs:{"src":comment.user.avatar,"alt":""}}),_c('div',[_c('router-link',{attrs:{"to":{
										name: 'PlayerHome',
										params: {
											lang: _vm.lang,
											console: _vm.console,
											playerSlug: comment.user.slug,
										},
									}}},[_vm._v(_vm._s(comment.user.nick))]),_c('hr'),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(comment.review))])],1)])}),0)]),(_vm.isAuthenticated)?_c('form',{staticClass:"form-comments col-12 col-md-8",on:{"submit":function($event){$event.preventDefault();return _vm.addComment.apply(null, arguments)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.comment),expression:"comment"}],staticClass:"form-control",attrs:{"type":"text","name":"comment","required":"","maxlength":"200"},domProps:{"value":(_vm.comment)},on:{"input":function($event){if($event.target.composing)return;_vm.comment=$event.target.value}}}),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.loading}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M24 0l-6 22-8.129-7.239 7.802-8.234-10.458 7.227-7.215-1.754 24-12zm-15 16.668v7.332l3.258-4.431-3.258-2.901z"}})])])]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }