<template>
	<div class="container-fluid">
		<div class="row justify-content-center">
			<div class="col-12 col-lg-10">
				<h2 class="mb-0 text-center">{{ notice.title }}</h2>
				<div class="notice-comment">
					<div>
						<i class="fa fa-comments"></i>
						<span class="ml-2">{{ notice.reviews }}</span>
					</div>
					<div>
						<span class="mr-2">{{ notice.views }}</span>
						<i class="fa fa-eye"></i>
					</div>
				</div>
				<img :src="notice.image" alt="" class="img-fluid img-thumbnail mb-3" />
				<div v-html="notice.content"></div>
				<br />
				<div class="row justify-content-center">
					<div class="col-12 col-md-8">
						<hr />
						<h6>{{ $t('comments') }}</h6>
						<ul class=" list-unstyled comments-list">
							<li v-for="comment in notice.my_reviews" :key="comment.id">
								<img :src="comment.user.avatar" alt="" />
								<div>
									<router-link
										:to="{
											name: 'PlayerHome',
											params: {
												lang: lang,
												console: console,
												playerSlug: comment.user.slug,
											},
										}"
										>{{ comment.user.nick }}</router-link
									>
									<hr />
									<p class="mb-0">{{ comment.review }}</p>
								</div>
							</li>
						</ul>
					</div>
					<form
						class="form-comments col-12 col-md-8"
						@submit.prevent="addComment"
						v-if="isAuthenticated"
					>
						<input
							type="text"
							name="comment"
							class="form-control"
							v-model="comment"
							required
							maxlength="200"
						/>
						<button type="submit" class="btn btn-primary" :disabled="loading">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
							>
								<path
									d="M24 0l-6 22-8.129-7.239 7.802-8.234-10.458 7.227-7.215-1.754 24-12zm-15 16.668v7.332l3.258-4.431-3.258-2.901z"
								/>
							</svg>
						</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			slug: this.$route.params.slug,
			notice: {},
			comment: '',
			comments: [],
		}
	},
	computed: {
		...mapGetters(['lang', 'console', 'isAuthenticated', 'loading']),
	},
	mounted() {
		this.$store.dispatch('SET_MENU_PRIMARY_DIVISION', 'home')
		this.fetchData()
	},
	methods: {
		addComment() {
			const slug = this.$route.params.slug
			const path = `auth/${this.lang}/${this.console}/blog/${slug}/comments/add`
			const payload = {
				comment: this.comment,
			}
			this.$axios.post(path, payload).then((response) => {
				this.notice.my_reviews.push(response.data.data)
				this.$toastr.success(response.data.message, window.TITLE_SUCCESS)
				this.comment = ''
			})
		},
		fetchData() {
			const lang = this.$route.params.lang
			const console = this.$route.params.console
			const slug = this.slug
			const path = `${lang}/console/${console}/notices/${slug}`
			this.$axios.get(path).then((response) => {
				this.notice = response.data.data
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.comments-list {
	li {
		display: flex;
		padding: 0.5rem;
		&:nth-child(odd) {
			background-color: #f4f5f8;
		}
	}
	a {
		&:hover {
			text-decoration: none;
		}
	}
	hr {
		margin: 0;
	}
	img {
		width: 50px;
		margin-right: 0.5rem;
	}
}
.form-comments {
	display: flex;
	align-items: center;
	height: 40px;
	.btn {
		height: 40px;
		padding-left: 20px;
		padding-right: 20px;
		path {
			fill: #fff;
		}
	}
}
.notice-comment {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.5rem 0;
	width: 300px;
	margin-left: auto;
	margin-right: auto;
	i {
		font-size: 1.25rem;
	}
}
</style>
